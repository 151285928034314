/*
 * 업무구분: 신탁
 * 파 일 명: TSHeader.vue
 * 파일설명: header 영역 (공통)
 * 접근권한: 
 * 작 성 일: 2023.04.07
 * 작 성 자: 송진의
 */
<template>

    <header class="fts-header">
      <ur-box-container alignV="center" direction="row" class="fts-flex-sb">
        <nav class="btn-content">
          <mo-icon-button class="fts-home" :borderless="true" @click="fn_GoHome()">홈으로</mo-icon-button>
          <mo-icon-button class="fts-gnb" :borderless="true" @click="fn_MenuGnb" v-if="!isStep">메뉴 열기/닫기</mo-icon-button>
          <span class="rec" v-if="isRecord">녹취중</span>
        </nav>
        <div class="fts-step-type" v-if="isStep">
          <div class="ing-content" >

            <div v-for="(stepItem, idx) in stepList.filter(p => p.type === getType)" :key="idx" 
                :class="stepItem.step == Number(getStep) ? 'checked' : stepItem.step < Number(getStep) ? 'completed' : ''" 
                class="ing-item">
              <span class="step">{{ stepItem.step }}</span>
              <mo-checkbox size="small" class="com-check" checked="checked" />
              <span class="list-step">{{ stepItem.name }}</span>
            </div>

          </div>
        </div>
      </ur-box-container>
      <ur-box-container alignV="start" direction="row" class="pt-2 fts-flex-sb">
        <h1>
          <!-- <mo-icon-button icon="previous" :borderless="true" :color="'black'" @click="fn_HistoryBack">이전 페이지로</mo-icon-button>  -->
          {{ propObj.title }}
        </h1>
      </ur-box-container>
      <!-- popup010 -->
      <msp-ts-010p
        ref="popup010"
        @ts-popup-callback="fn_Popup_CallBack"
        :popupObj="pPopup010Obj"
      ></msp-ts-010p>
      <!-- alert modal popup -->
      <ts-alert-popup 
        ref="alertPopup"
        :popupObj="pAlertPopupObj"
      ></ts-alert-popup>
    </header>

</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/    
  import TSMenuUtil from '~/src/ui/ts/comm/TSMenuUtil'
  import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
  import TSInfoUtil from '~/src/ui/ts/comm/TSInfoUtil'
  import MSPTS010P from '~/src/ui/ts/MSPTS010P'  // 전체메뉴  
  import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup'  // Alert 팝업 (공통)
  import PSDateUtil from '@/ui/ps/comm/PSDateUtil'

  export default {
    name: 'TSHeader',
    screenId: 'TSHeader',
    props: {
      propObj: {type:Object, default:null},
    },
    components: {
      'msp-ts-010p': MSPTS010P,
      'ts-alert-popup': TSAlertPopup,
    },
    data() {
      return {
        pAlertPopupObj: {},
        pPopup010Obj: {},        
        stepList:[          
          {type:'custInfoReg' , step:1, name:'고객등록'}, // 고객정보등록
          {type:'custInfoReg' , step:2, name:'신규입력'},
          {type:'custInfoReg' , step:3, name:'신탁입금'},
          {type:'custInfoReg' , step:4, name:'송금계좌등록'},          
          {type:'chgRscTrsf' , step:1, name:'해지등록'}, // 변경/해지/이체
          {type:'chgRscTrsf' , step:2, name:'신탁출금'},
          {type:'chgRscTrsf' , step:3, name:'이체처리명세'},
          {type:'chgRscTrsf' , step:4, name:'신탁이익계산서'},
          {type:'chgRscTrsf' , step:5, name:'원천영수증'},          
          {type:'trsf' , step:1, name:'추가입금신청'},   // 변경/해지/이체>이체
          {type:'trsf' , step:2, name:'신탁입금'},          
        ],
        lv_basInfo: this.getStore('tsStore').getters.getBasInfo.data,
        lv_subProcPages: [
          { 
            type: 'custInfoReg',
            disp: 'MSPTS200M', // 표시 단계 : 추가입금/변경신청 (2단계)
            page: ['MSPTS104M','MSPTS105M'] // 현재 페이지 지정 필요
          },
          { 
            type: 'custInfoReg',
            disp: 'MSPTS207M', // 표시 단계 : 신탁입금 (3단계)
            page: ['MSPTS209M','MSPTS210M','MSPTS211M'] // 현재 페이지 지정 필요
          },
          { 
            type: 'chgRscTrsf',
            disp: 'MSPTS204M', // 표시 단계 : 원천징수영수증발급 (5단계)
            page: ['MSPTS000M'] // 현재 페이지 지정 필요
          },
          { 
            type: 'trsf',
            disp: 'MSPTS207M', // 표시 단계 : 신탁입금 (2단계)
            page: ['MSPTS000M','MSPTS210M','MSPTS211M'] // 현재 페이지 지정 필요
          }
        ], // 프로세스 진행 시 단계 표시

        lv_isMobile: window.vue.getStore('deviceState').getters.getIsMobile,

        // 녹취 관련 
        busnScCd: '', //업무구분코드
        recdYmd: '', //녹취일자
        recdStrTm: '', //녹취시작시간
        recdGudeEno: '', //녹취안내자사번
        recdTotPhclTm: '', //녹취총통화시간
        recdFileNm: '', //녹취파일명
        custId: '', //고객ID
        custNm: '', //고객명
        custRrnEncr: '', //고객주민등록번호암호화
        custTelnoEncr: '', //고객전화번호암호화
        nrmTrtYn: 'Y', //정상처리여부
        lstTrtYn: 'N', //최종처리여부
        recdTrtDtm: '', //녹취처리일시
        currDt: '', //녹취시작일시
        endDt: '', //녹취종료일시        
        doubleUploadFlag_NAS: false // 녹취파일 중복 업로드 체크
      }
    },
    computed: {
      isRecord() {
        return this.getStore('tsStore').getters.getState.isRecording
      },
      isStep() {
        return this.getStore('tsStore').getters.getState.isProcess
      },
      getStep() {
        return this.fn_GetStep()
      },
      getType() {        
        return this.getStore('tsStore').getters.getBasInfo.data.procTyp
      },
      getRecInfo() {        
        return this.getStore('tsStore').getters.getBasInfo.data.recInfo
      },
      getCustInfo() {        
        return this.getStore('tsStore').getters.getBasInfo.data.custInfo
      },
      getSendMth() {        
        return this.getStore('tsStore').getters.getBasInfo.data.sendMth
      },      
    },
    mounted () {    
      document.getElementsByClassName('mo-page__contents-wrapper')[0].addEventListener("scroll", this.fn_closeDropdown)      
      let recStat = this.fn_GetRecStat()
      console.log('현재 녹취 상태 [TSHeader - created] : ' , recStat)
      // 개인, 조력지지정
      if (this.lv_basInfo.vstTyp === 'vst') { // 2023.10.18 정수진 ASR231000514 / 태블릿 내방 신규계약 프로세스시 녹취기능 비활성화
        if(this.getStep === 1 && this.getType === 'custInfoReg' || this.$route.name === 'MSPTS107M') {
          if(recStat !== 'Playing') {
            let t_popupObj = {
              btnType: 3,
              cancel: true,
              confirm: true,
              closeFunc: this.fn_GoPage,
              confirmFunc: this.fn_StartRecord,
              content: ['녹취와 함께 업무를','진행하시겠습니까?'],
              contentTitle: '',
            }
            this.fn_AlertPopup(8, t_popupObj)
          }
        }
      }
      
    },
    created () {      
      
    },
    beforeDestroy() {
      document.getElementsByClassName('mo-page__contents-wrapper')[0].removeEventListener("scroll", this.fn_closeDropdown)
    },
    methods: {
      /******************************************************************************
       * Function명 : fn_closeDropdown
       * 설명       : 스크롤 이동시 dropdown 초기화      
       ******************************************************************************/
      fn_closeDropdown() {
        for (let i=0; i<document.getElementsByClassName('mo-dropdown__items').length; i++) {
          document.getElementsByClassName('mo-dropdown__title')[i].classList.remove("mo-dropdown__title--active")
          document.getElementsByClassName('mo-dropdown__icon')[i].style.transform = "rotate(0deg)"
          document.getElementsByClassName('mo-dropdown__items')[i].style.display = "none"
        }
      },

      async fn_GetRecStat() {            
        if(this.lv_isMobile){
          return await this.$commonUtil.getRecStatus(this)
        } else {        
          return this.isRecord ? 'Playing' : 'Stop'
        }
      },

      /******************************************************************************
       * Function명 : fn_GetStep
       * 설명       : 현재 화면에 따른 스텝 설정       
       ******************************************************************************/
      fn_GetStep () {
        let rtn = ''        
        let t_IsProcess = this.getStore('tsStore').getters.getState.isProcess
        if (!t_IsProcess) {
          return false
        }
        
        let curScrnId = this.$route.name        
        let ids = []
        if(this.getType === 'custInfoReg') {
          ids = TSMenuUtil.custInfoReg
        } else if(this.getType === 'chgRscTrsf') {
          ids = TSMenuUtil.chgRscTrsf     
        } else if(this.getType === 'trsf') {
          ids = TSMenuUtil.trsf     
        }

        /**
         * 프로세스 진행 중일 때, 
         * 1. 고객정보등록 프로세스 인 경우, "지정필요" 일때, 신탁입금(3단계)로 표시
         * 2. 변경/해지/이체 프로세스 인 경우, "지정필요" 일때, "지정필요"로 표시
         * 3. 추가입금 프로세스 인 경우, "지정필요" 일때, "지정필요"로 표시
         */
        let subObj = this.lv_subProcPages.filter(p => p.type === this.getType)
        let findPageIdx = -1
        if(subObj.length > 0) {
          subObj.forEach(subItem => {
            findPageIdx = subItem.page.findIndex(item => item === curScrnId) // 현재 페이지 검사
            if(findPageIdx >= 0) { // - 1 이 아니면
              rtn = ids.findIndex(item => item === subItem.disp) // 표시되는 스텝
            } else {
              rtn = ids.findIndex(item => item === curScrnId)
            }
          })
        } else { // *현재 표시 단계 없는 경우는 없음 (사용안함)
          rtn = ids.findIndex(item => item === curScrnId)          
        }

        return Number(rtn) + 1
      },

      /******************************************************************************
       * Function명 : fn_historyBack
       * 설명       : 뒤로가기 버튼
       ******************************************************************************/
      fn_HistoryBack () {
        let t_IsProcess = this.getStore('tsStore').getters.getState.isProcess
        if (!t_IsProcess) {
          this.$router.go(-1)
        } else {
          // 프로세스 진행 중 일 때,
          // let curScrnId = this.$route.name
          if(this.getType === 'custInfoReg'|| this.getType === 'chgRscTrsf'|| this.getType === 'trsf') {
            this.$router.push({ name: 'MSPTS000M'})
          }    
        }
      },

      /******************************************************************************
       * Function명 : fn_GoHome
       * 설명       : INDEX 페이지로 이동
       ******************************************************************************/
      fn_GoHome () {
        // console.log('fn_GoHome...')
        let t_IsProcess = this.getStore('tsStore').getters.getState.isProcess
        let t_IsProcUnit = this.getStore('tsStore').getters.getState.isProcUnit

        let t_formInfo = this.getStore('tsStore').getters.getBasInfo.data.formInfo
        let t_type = 0

        // 1-1. 리포트가 있을 때,
        if(!this.$bizUtil.tsUtils.isEmptyObj(t_formInfo)){
          t_type = 9
          let t_popupObj = {
            btnType: 2,
            cancel: true,
            confirm: true,
            confirmFunc: this.fn_ReportPrint,
            content: ['*중단된 업무는 재진입 시 처음부터','다시 진행해야 합니다.'],
            contentTitle: '화면이동 시 현재 업무가 중단됩니다. 이동하시겠습니까?',
          }
          this.fn_AlertPopup(t_type, t_popupObj)
        } else {
          // 1-2. 리포트가 없을 때,
          this.fn_GoPage()
        }

        return
      },

      /******************************************************************************
       * Function명 : fn_ReportPrint
       * 설명       : 중단 시 전자서식 출력
       ******************************************************************************/
      async fn_ReportPrint() {        
        TSInfoUtil.commReportInfo(this, [], function() {}, true, this.$route.name, true)
      },

      /******************************************************************************
       * Function명 : fn_GoPage
       * 설명       : 중단 시 홈으로 이동
       ******************************************************************************/
      async fn_GoPage() {
        this.$router.push({ name: 'MSPTS000M'})
        let recStat = await this.fn_GetRecStat()
        console.log('현재 녹취 상태 [TSHeader] - fn_GoPage : ' , recStat)
        if(recStat === 'Playing') {
          this.fn_endRecord()
        }
      },

      /******************************************************************************
       * Function명 : fn_MenuGnb
       * 설명       : 메뉴 Open/Close
       ******************************************************************************/
      fn_MenuGnb () {
        // console.log('fn_MenuGnb...')
        this.$refs.popup010.fn_Open()
      },
      
      /******************************************************************************
      * Function명 : fn_AlertPopup
      * 설명       : alert popup 호출(공통)
      ******************************************************************************/
      fn_AlertPopup (type, pPopupObj) {
        if( !TSCommUtil.gfn_isNull(pPopupObj) ) {
          TSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)              
        }
        this.$refs.alertPopup.fn_Open()
      },

    /******************************************************************************
     * Function명 : fn_Popup_CallBack
     * 설명       : popup 컴포넌트에서 callback
     * 파라미터   : 호출한 페이지 파라미터
     ******************************************************************************/
      fn_Popup_CallBack(type, pData) {
        switch (type) {
          case 0:                
            this.lv_isStep = pData.isStep
            break         
        }
      },

      /******************************************************************************
       * Function명 : fn_StartRecord
       * 설명       : 레코딩 실행 (방문 > 녹취 시작 동의 시 > 메인)
       ******************************************************************************/
      fn_StartRecord() {        
        // 녹취 정보 저장
        let t_IsProcess = this.getStore('tsStore').getters.getState.isProcess        
        if(!(this.getStep === 1 && this.getType === 'custInfoReg' && t_IsProcess) && this.$route.name !== 'MSPTS107M') {
          return
        }
        
        let lv_vm = this
        let pParams = {}        
        window.vue.post(lv_vm, pParams, 'txFSRCD10S1', '')
          .then((response) => {
            try {
              console.log("response....................."+JSON.stringify(response));
              let encrKey = response.body.encrKey; // ksign 
              let encrUUID = response.body.encrUUID; // 암호화
              console.log("key/uuid..............." + encrKey + ".../..." + encrUUID);
              if(process.env.NODE_ENV === 'local'){
                encrKey = '3447691E0932696B5397B7EA83AD40995C3E5431AA1081166B2AD3110C476650'
                encrUUID = 'b3db2f2c-21ae-4713-b079-dc7bea48ec28'
              }
              if (encrKey == null || encrKey == ""){
                lv_vm.$addSnackbar('암호키 생성에 실패하여 녹취가 정상적으로 시작되지 않았습니다.')
                lv_vm.getStore('tsStore').dispatch('IS_RECORDING_STOP')                
                return
              }
              lv_vm.fn_startRecodeRslt(encrKey,encrUUID);
            } catch (e) {
              window.vue.error(e)  
            }
          })
          .catch((error) => {
            window.vue.error(error)
          })

      },

      /******************************************************************************
       * Function명 : fn_startRecodeRslt
       * 설명       : 녹취 정보 시작
       ******************************************************************************/
      fn_startRecodeRslt(key, uuid) {        
        this.currDt = PSDateUtil.fn_CurrentDate('yyyyMMddHHmmss')
        let userEno =  this.getStore('userInfo').getters.getUserInfo.userId
        let recdYmd = this.currDt.substr(0,8)
        let recdStrTm = this.currDt.substr(8,6)
        let recdFileNm = this.currDt + '_' + userEno             // 날짜시분초 + 고객ID(사용자ID)
        if (userEno == null || userEno == "") userEno = "99999" // 사원번호 없는 경우        

        // 녹취 정보
        let t_recRnfo = {
          recdId: this.$route.name + this.getType,
          recdFileNm: recdFileNm,
          recdGudeEno: userEno,
          busnScCd: '06',
          recdStrTm: recdStrTm,
          recdYmd: recdYmd,
          currDt: this.currDt
        }

         // 녹취 정보 저장
        this.$bizUtil.tsUtils.saveRecInfo(this, t_recRnfo)

        if(!this.lv_isMobile) {
          this.$addSnackbar('녹취를 시작합니다.')
          this.getStore('tsStore').dispatch('IS_RECORDING')
          return
        }
       
        let lv_vm = this
        window.fdpbridge.exec('RecordStartPlugin', {fileNm: recdFileNm, key, uuid}, () => {          
          lv_vm.$addSnackbar('녹취를 시작합니다.')
          lv_vm.getStore('tsStore').dispatch('IS_RECORDING')
        } , () => {
          lv_vm.$addSnackbar('녹취 시작 실패했습니다.')
          lv_vm.getStore('tsStore').dispatch('IS_RECORDING_STOP')
        })
      },

      /******************************************************************************
      * Function명 : fn_strToDate
      * 설명       : 녹취 시간 
      ******************************************************************************/   
      fn_strToDate(sDate){
        let nYear = parseInt(sDate.substr(0, 4));
        let nMonth = parseInt(sDate.substr(4, 2)) - 1;
        let nDate = parseInt(sDate.substr(6, 2));
        
        let nHour = parseInt(sDate.substr(8, 2));
        let nMin = parseInt(sDate.substr(10, 2));
        let nSec = parseInt(sDate.substr(12, 2));
        
        return new Date(nYear, nMonth, nDate, nHour, nMin, nSec);
      },

      /******************************************************************************
      * Function명 : fn_getCalRecTotTime
      * 설명       : 녹취 시간 계산
      ******************************************************************************/
      fn_getCalRecTotTime(dateTime) {        
        if(this.$bizUtil.isEmpty(dateTime)) {
          return ''
        }

        let currDtOfEnd = PSDateUtil.fn_CurrentDate('yyyyMMddHHmmss')
        let nDiffDate = this.fn_strToDate(currDtOfEnd) - this.fn_strToDate(dateTime)
        return parseInt(nDiffDate / 1000)
      },

      /******************************************************************************
      * Function명 : fn_endRecord
      * 설명       : 녹취 종료 액션
      ******************************************************************************/   
      fn_endRecord() {        
        if(this.$bizUtil.isEmpty(this.getRecInfo)){
          return
        }
        
        this.recdTotPhclTm = ''
        if(!this.lv_isMobile){
          this.recdTotPhclTm = this.fn_getCalRecTotTime(this.getRecInfo.currDt)
          this.fn_transRecodeFile()
          this.fn_insRecdFile()          
          this.getStore('tsStore').dispatch('IS_RECORDING_STOP')
          this.$addSnackbar('녹취가 종료되었습니다.')
          return
        }

        window.fdpbridge.exec('RecordStopPlugin', {fileNm: this.getRecInfo.recdFileNm}, 
        () => { // success        
          this.recdTotPhclTm = this.fn_getCalRecTotTime(this.getRecInfo.currDt)
          this.$addSnackbar('녹취가 종료되었습니다.')
          this.fn_transRecodeFile()
        }, () => { // fail
          this.getStore('tsStore').dispatch('IS_RECORDING_STOP')
          this.$addSnackbar('녹취가 정상적으로 종료되지 않았습니다.')
        })
      },

      /******************************************************************************
      * Function명 : fn_transRecodeFile
      * 설명       : 녹취 화일 NAS로 전송
      ******************************************************************************/       
      async fn_transRecodeFile() {
        // 녹취파일 업로드 중복처리 방지
        if ( this.doubleUploadFlag_NAS ) {
          this.$addSnackbar("녹취화일을 전송 중 입니다.")
          return
        }
        let lv_vm = this
        let jobPath = "REC_FILE_DIR_TS"        
        let t_Param = {
          serverurl:jobPath,
          fileNm: this.getRecInfo.recdFileNm, // 저장파일명
        }          
        // this.fn_ShowLoading(true, '2') // 화면 로딩 처리 // 화면 로딩
        lv_vm.doubleUploadFlag_NAS = true
          window.fdpbridge.exec('RecordUploadPlugin', t_Param, function (result) {
            console.log('RecordUploadPlugin::result::::', JSON.stringify(result))
            lv_vm.doubleUploadFlag_NAS = false            
            if (result.data === '200' || result.data === 'ok') {
              lv_vm.fn_insRecdFile() //녹취파일 정상           
            } else {
              lv_vm.fn_insRecdFile(1) //녹취파일 오류
            } // end else if
          }, function (result) {
              lv_vm.fn_insRecdFile(2) //녹취파일 오류              
          })
      },

      /******************************************************************************
      * Function명 : fn_insRecdFile
      * 설명       : 녹취 화일 저장 오류 처리
      ******************************************************************************/       
      async fn_insRecdFile (err) {
        let nrmTrtYn = ''
        if (this.$bizUtil.isEmpty(err)) {
          if(process.env.NODE_ENV !== 'local'){
            await this.$commonUtil.delRecData(this, this.getRecInfo.recdFileNm)
          }          
          nrmTrtYn = 'Y'          
        } else {
          nrmTrtYn = 'N'
        }
        
        let recdTrtDtm = PSDateUtil.fn_CurrentDate('yyyyMMddHHmmss')        
        let custData = {
          custId: '',
          custNm: '',
          rrnAesEncr: '',
          custTelnoEncr: ''
        }

        let tmpCustData = {
          custId: '9999900000',
          custNm: '비추미',
          rrnAesEncr: '9999990000000',
          custTelnoEncr: '01099990000',
        }

        if(!this.$bizUtil.isEmpty(this.getCustInfo)) {
          try {
            custData.custId = !this.$bizUtil.isEmpty(this.getCustInfo.csId)? this.getCustInfo.csId : tmpCustData.custId
            custData.custNm = !this.$bizUtil.isEmpty(this.getCustInfo.csNm)? this.getCustInfo.csNm : tmpCustData.custNm
            custData.rrnAesEncr = !this.$bizUtil.isEmpty(this.getCustInfo.rcno)? this.getCustInfo.rcno : tmpCustData.rrnAesEncr            
            custData.custTelnoEncr = !this.$bizUtil.isEmpty(this.getCustInfo.celph)? this.$bizUtil.replaceAll(this.getCustInfo.celph, '-', '') : tmpCustData.custTelnoEncr
          } catch (e) {
            custData.rrnAesEncr = tmpCustData.rrnAesEncr
            custData.custTelnoEncr = tmpCustData.custTelnoEncr
            custData.custId = tmpCustData.custId
            custData.custNm = tmpCustData.custNm            
          }          
        } else { // 데이터가 없을 때 임시 데이터 입력
          custData.rrnAesEncr = tmpCustData.rrnAesEncr
          custData.custTelnoEncr = tmpCustData.custTelnoEncr
          custData.custId = tmpCustData.custId
          custData.custNm = tmpCustData.custNm
        }

        if(process.env.NODE_ENV !== 'local'){
          try {
            custData.rrnAesEncr = await this.$commonUtil.getEncriptData(this, custData.rrnAesEncr)
            custData.custTelnoEncr = await this.$commonUtil.getEncriptData(this, custData.custTelnoEncr)            
            custData.rrnAesEncr = custData.rrnAesEncr.trim().replace(/\n/g, '')
            custData.custTelnoEncr = custData.custTelnoEncr.trim().replace(/\n/g, '')
          } catch (e) {
            window.vue.error(e)  
          }
        }

        let lv_vm = this
        let pParams = {
          busnScCd: this.getRecInfo.busnScCd,
          recdFileNm: this.getRecInfo.recdFileNm + '.mp3.crypt',
          recdStrTm: this.getRecInfo.recdStrTm,
          recdGudeEno: this.getRecInfo.recdGudeEno,
          recdYmd: this.getRecInfo.recdYmd,
          recdTotPhclTm: this.recdTotPhclTm,
          custId: custData.custId,
          custNm: custData.custNm,
          rrnAesEncr: custData.rrnAesEncr,   ////////////////// 차후 변경 this.custRrnEncr
          custTelnoEncr: custData.custTelnoEncr,   ////////////////// 차후 변경 this.custTelnoEncr
          nrmTrtYn: nrmTrtYn,
          lstTrtYn: this.lstTrtYn,
          recdTrtDtm: recdTrtDtm
        }        
        // alert('fn_insRecdFile - param : ' + JSON.stringify(pParams))
        window.vue.post(lv_vm, pParams, 'txTSSBC99I1', '')
          .then((response) => {
            try {
              // alert('fn_insRecdFile - response : ' + JSON.stringify(response))
              lv_vm.getStore('tsStore').dispatch('IS_RECORDING_STOP')
              console.log("insert response....................."+JSON.stringify(response));
            } catch (e) {
              window.vue.error(e)  
            } 
          })
          .catch((error) => {
            window.vue.error(error)
          })
      },
      
      /************************************************************************************************
       * Function명  : fn_ShowLoading
       * 설명        : 화면 로딩 처리 공통 메소드
       ************************************************************************************************/
      fn_ShowLoading: function (isShow, showType = ' ') {
        console.log('this.fn_ShowLoading Call', 'showType : ' + showType)
        this.isShowLoading = isShow // 화면 로딩
        if (showType === '0') {
          this.isShowLoading1 = false // 진행 중
          this.isShowLoading2 = false // 처리 중
        } else if (showType === '1') {
          this.isShowLoading1 = true // 진행 중
          this.isShowLoading2 = false // 처리 중
        } else if (showType === '2') {
          this.isShowLoading1 = false // 진행 중
          this.isShowLoading2 = true // 처리 중
        } // end else if
      },
    }
  };
</script>